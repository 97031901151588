import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Form,
  Row,
  Col,
  InputGroup,
  Button,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";

function App() {
  const apiEndPoint = process.env.REACT_APP_BASE_URL;

  const [controls, setControls] = useState({
    sentence: "",
    threshold: "0.91",
    ngram: "3",
    application: "",
    environment: "",
  });
  const [applicationEnvironment, setApplicationEnvironment] = useState({});
  const [inputType, setInputType] = useState("sentence");
  const [result, setResult] = useState({ sqlQuery: "", queryResult: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  useEffect(() => {
    (async () => {
      try {
        const global = await axios.get(`${apiEndPoint}/api/v1/inl2sql/globals`);
        setApplicationEnvironment(global.data.data.applicationsEnvironments);
        const firstApplicationEnvironment =
          global.data.data.applicationsEnvironments[0];
        setControls((prev) => ({
          ...prev,
          application: firstApplicationEnvironment.application,
          environment: firstApplicationEnvironment.environments[0],
        }));
      } catch (error) {
        console.error("global", error);
      }
    })();
  }, []);

  const handleControls = (e) => {
    setControls((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleApplicationEnvironment = (e) => {
    const label = document.querySelector(
      "#application-environment option:checked"
    ).parentElement.label;
    const environment = e.target.value;
    setControls((prev) => ({
      ...prev,
      application: label,
      environment,
    }));
  };

  const generateFormData = (dataObj) => {
    if (dataObj.sentence) {
      dataObj.sentence = dataObj.sentence.toLowerCase();
    }
    if (dataObj.environment.includes("-")) {
      dataObj.environment = dataObj.environment.split("-")[1];
    }
    const formData = new FormData();
    for (const prop in dataObj) {
      formData.append(prop, dataObj[prop]);
    }
    return formData;
  };

  const GetAppEnvOption = () => {
    return (
      <>
        {applicationEnvironment.length &&
          applicationEnvironment.map((item, index) => (
            <optgroup key={index} label={item.application}>
              {item.environments.map((env, i) => (
                <option key={i} value={`${item.application}-${env}`}>
                  {env}
                </option>
              ))}
            </optgroup>
          ))}
      </>
    );
  };

  const handleResult = async () => {
    try {
      setIsLoading(true);
      setError({ isError: "", message: "" });
      setResult({ sqlQuery: "", queryResult: "" });
      if (inputType === "sentence") {
        const formData = generateFormData(controls);
        const response = await axios.post(
          `${apiEndPoint}/api/v1/inl2sql/query/`,
          formData
        );
        setResult((prev) => ({
          ...prev,
          sqlQuery: response.data.data.responseText,
          queryResult: response.data.data.queryResult,
          tableName: response.data.data.tableName,
          tableRelation: response.data.data.tableRelation,
        }));
      } else {
        const queryFormData = {
          sqlquery: controls.sentence,
          application: controls.application,
          environment: controls.environment,
        };
        const formData = generateFormData(queryFormData);
        const response = await axios.post(
          `${apiEndPoint}/api/v1/inl2sql/sqlquery/`,
          formData
        );
        setResult({ queryResult: response.data.data.queryResult });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError({ isError: true, message: error.response.data.message });
      console.error("Result", error);
    }
  };

  return (
    <div className="App">
      <Container>
        <h1 className="page-title pt-1">INL2SQL</h1>
        <Row className="justify-content-md-center mt-3 mb-5 tab-ctn">
          <Col lg="10">
            <Col lg="3" className="app-env-ctn">
              <Form.Label>Application/Environment</Form.Label>
              <Form.Select
                id="application-environment"
                onChange={(e) => handleApplicationEnvironment(e)}
                value={controls.environment}
              >
                <GetAppEnvOption />
              </Form.Select>
            </Col>
            <h5>Input type</h5>
            <Tabs
              id="controlled-tab-example"
              activeKey={inputType}
              onSelect={(k) => setInputType(k)}
            >
              <Tab eventKey="sentence" title="Sentence">
                <Row className="justify-content-md-center pt-3">
                  <Col lg="8">
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        name="sentence"
                        value={controls.sentence}
                        onChange={(e) => handleControls(e)}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg="4">
                    <Row>
                      <Col lg="12">
                        <Form.Group>
                          <Form.Label>Ngram</Form.Label>
                          <Form.Control
                            type="number"
                            min="1"
                            max="5"
                            name="ngram"
                            value={controls.ngram}
                            onChange={(e) => handleControls(e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="12" className="pt-2">
                        <Form.Group>
                          <Form.Label>
                            Threshold: {controls.threshold}
                          </Form.Label>
                          <Form.Range
                            name="threshold"
                            min="0"
                            max="1"
                            step="0.01"
                            value={controls.threshold}
                            onChange={(e) => handleControls(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg="12">
                    <div className="d-grid">
                      <Button onClick={() => handleResult()}>Get Result</Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="sql-query" title="SQL Query">
                <Row className="justify-content-md-center pt-3">
                  <Col lg="12">
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        name="sentence"
                        value={controls.sentence}
                        onChange={(e) => handleControls(e)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="pt-2">
                  <Col lg="12">
                    <div className="d-grid">
                      <Button onClick={() => handleResult()}>Get Result</Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        {isLoading && (
          <Row className="justify-content-md-center">
            <Col lg="1">
              <Spinner animation="border" variant="primary" />
            </Col>
          </Row>
        )}

        <Row className="justify-content-md-center">
          <Col lg="10">
            {error.isError ? (
              <p>{error.message}</p>
            ) : (
              <Row>
                <Col lg="8">
                  <Row>
                    <Col lg="12">
                      {result.sqlQuery && (
                        <div className="pd-b-2">
                          <h5 className="pd-b-1">SQL Response Query</h5>
                          <p>{result.sqlQuery}</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      {result.queryResult && (
                        <div className="pd-b-2">
                          <h5 className="pd-bt-1">Query Result</h5>
                          <p>{result.queryResult}</p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col lg="4">
                  <Row>
                    <Col lg="12">
                      {result.tableName && (
                        <div className="pd-b-2">
                          <h5 className="pd-bt-1">Selected Table</h5>
                          <p className="result-table p-2 rounded">
                            {result.tableName}
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      {result.tableRelation && (
                        <div className="pd-b-2">
                          <h5 className="pd-bt-1">Selected Table Mapping</h5>
                          <p className="result-table p-2 rounded">
                            {result.tableRelation.length
                              ? result.tableRelation.join(", ")
                              : "-"}
                          </p>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
